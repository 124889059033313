import { useMutation } from 'react-query';
import { userApi } from '../../api';
import { User } from '../../types';

export const useAddUpdateUser = () => {
  const mutation = useMutation((user: User): any => {
    const data = { ...user, role: null, employee: null };
    return user?.id ? userApi.update(data) : userApi.add(data);
  });
  return mutation;
};
