import { useMutation } from 'react-query';
import { roleApi } from '../../api';

type MytationData = {
  id: number;
  toInclude: number[];
  toExclude: number[];
};

export const useChangeOrgUnit = () => {
  const mutation = useMutation(
    ({ id, toInclude, toExclude }: MytationData): any => {
      return roleApi.changeOrgUnit(id, toInclude, toExclude);
    },
  );
  return mutation;
};
