import { Button, Grid } from '@mui/material';
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { Header } from '../../components/layouts';
import { Breadcrumbs, Card, Label } from '../../components/ui';
import { useUser } from '../../lib/hooks';

const Form = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { isLoading, data: user } = useUser(Number(id));
  return (
    <>
      <Header
        title="Пользователь"
        right={
          <Button onClick={() => navigate(`/admin/users/${id}/edit`)}>
            Редактировать
          </Button>
        }
      />
      <Breadcrumbs
        crumbs={[
          { label: 'Главная', to: '/' },
          { label: 'Пользователи', to: '/admin/users' },
          { label: 'Карточка' },
        ]}
      />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Label
              title="Имя пользователя"
              text={user?.userName ?? ''}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={6}>
            <Label
              title="Роль"
              text={user?.role?.name ?? '-'}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};
export default Form;
