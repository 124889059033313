import { List, Form, Card } from '../../pages/Users';
import { accessTypeEnum } from '../../lib/enums';

const users = [
  {
    component: List,
    path: '/admin/users',
    access: [accessTypeEnum.admin],
  },
  {
    component: Form,
    path: '/admin/users/new',
    access: [accessTypeEnum.admin],
  },
  {
    component: Card,
    path: '/admin/users/:id',
    access: [accessTypeEnum.admin],
  },
  {
    component: Form,
    path: '/admin/users/:id/edit',
    access: [accessTypeEnum.admin],
  },
];
export default users;
