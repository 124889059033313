import styled from "@emotion/styled";
import { Typography } from "@mui/material";

export const Background = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f4f8;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: 280px;
  width: 350px;
`;
export const SupportInfo = styled.p`
  color: #98a3ad;
  padding-top: 10px;
  font-size: 0.8em;
  position: absolute;
  right: 10px;
  bottom: 10px;
`;
export const StyledTypography = styled(Typography)`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 6px;
`;
export const LogoWrapper = styled.div`
  margin-bottom: 36px;
  display: flex;
  align-items: center;
`;
export const LogoTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const LoginContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: 500px;
  width: 400px;
`;
