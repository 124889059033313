import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { Header } from '../../components/layouts';
import { Breadcrumbs, Card } from '../../components/ui';
import { useAddUpdateLocation, useLocation } from '../../lib/hooks';
import { Location } from '../../lib/types';
import InnerForm from './components/InnerForm';

const route = '/admin/locations';
const Form = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const mutation = useAddUpdateLocation();
  const location = useLocation(Number(id));
  const handleSubmit = (location: Location) => {
    mutation.mutate(location, {
      onSuccess: () => {
        navigate(`${route}`);
      },
    });
  };
  const handleCancel = () => {
    navigate(-1);
  };
  return (
    <>
      <Header
        title={id ? 'Редактирование объекта' : 'Добавление нового объекта'}
      />
      <Breadcrumbs
        crumbs={[
          { label: 'Главная', to: '/' },
          { label: 'Объекты', to: route },
          { label: 'Объект' },
        ]}
      />
      <Card>
        <InnerForm
          isLoading={location.isLoading}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          location={
            location?.data
              ? (location.data as unknown as Location)
              : ({} as Location)
          }
        />
      </Card>
    </>
  );
};
export default Form;
