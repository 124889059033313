import { List, Form } from '../../pages/Roles';
import { accessTypeEnum } from '../../lib/enums';

const roles = [
  {
    component: List,
    path: '/admin/roles',
    access: [accessTypeEnum.admin],
  },
  {
    component: Form,
    path: '/admin/roles/new',
    access: [accessTypeEnum.admin],
  },
  {
    component: Form,
    path: '/admin/roles/:id/edit',
    access: [accessTypeEnum.admin],
  },
];
export default roles;
