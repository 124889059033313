import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { difference } from 'lodash';

import { Header } from '../../components/layouts';
import { Breadcrumbs, Card } from '../../components/ui';
import { useAddUpdateRole, useChangeOrgUnit, useRole } from '../../lib/hooks';
import { Role } from '../../lib/types';
import InnerForm from './components/InnerForm';

const route = '/admin/roles';
const Form = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const mutation = useAddUpdateRole();
  const mutationChangeOrgUnit = useChangeOrgUnit();
  const role = useRole(Number(id));
  const handleSubmit = (role: Role & { localOrganizationUnits?: number[] }) => {
    const { localOrganizationUnits, organizationUnits } = role;
    mutation.mutate(role, {
      onSuccess: (role) => {
        mutationChangeOrgUnit.mutate(
          {
            id: (role as Role).id,
            toInclude: localOrganizationUnits as number[],
            toExclude: difference(
              organizationUnits?.map(({ id }) => id) ?? [],
              localOrganizationUnits ?? [],
            ),
          },
          {
            onSuccess: () => {
              navigate(route);
            },
          },
        );
      },
    });
  };
  const handleCancel = () => {
    navigate(-1);
  };
  return (
    <>
      <Header title={id ? 'Редактирование роли' : 'Добавление новой роли'} />
      <Breadcrumbs
        crumbs={[
          { label: 'Главная', to: '/' },
          { label: 'Роли', to: route },
          { label: 'Роль' },
        ]}
      />
      <Card>
        <InnerForm
          isLoading={role.isLoading}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          role={role?.data ? (role.data as unknown as Role) : ({} as Role)}
        />
      </Card>
    </>
  );
};
export default Form;
