import { useQuery } from 'react-query';
import { userApi } from '../../api';

export const useUser = (id: number) => {
  const { isLoading, data } = useQuery(
    ['getUser', id],
    () => {
      return userApi.get(id);
    },
    {
      enabled: Boolean(id),
    }
  );
  return { isLoading, data };
};
