import {
  fetchRequest,
  initialFetchListParams,
  FetchListParams,
  ListResponse,
} from './fetchRequest';

export type CommonApiMethod = {
  add: Function;
  update: Function;
  updatePut: Function;
  delete: Function;
  get: Function;
  fetch: Function;
};

/**
 * Общий класс для выполнения оперций CRUD
 * @param {string} url Строка запроса
 * @param {string} name Наименование сущностей для вывода ошибок
 * @param {Function} convertDataToServer Функция конфертации данных на сервер
 * @param {Function} convertDataFromServer Функция конфертации данных c сервера
 */
export class CommonApi<T, F = {}, Fetch = T> {
  url = '';
  name = '';
  convertDataToServer: ((d: T) => T) | undefined = undefined;
  convertDataFromServer: ((d: T) => T) | undefined = undefined;

  constructor(
    url: string,
    name: string,
    convertDataToServer?: ((d: T) => T) | undefined,
    convertDataFromServer?: ((d: T) => T) | undefined
  ) {
    this.url = url;
    this.name = name;
    this.convertDataToServer = convertDataToServer;
    this.convertDataFromServer = convertDataFromServer;
  }

  add = async (data: T): Promise<T> => {
    const added = await fetchRequest.post(
      `/${this.url}`,
      this.convertDataToServer ? this.convertDataToServer(data) : data
    );
    if (added)
      return this.convertDataFromServer
        ? this.convertDataFromServer(added)
        : added;
    throw new Error(`Не удалось создать ${this.name}`);
  };

  update = async (data: T): Promise<T> => {
    const updated = await fetchRequest.patch(
      `/${this.url}`,
      this.convertDataToServer ? this.convertDataToServer(data) : data
    );
    if (updated)
      return this.convertDataFromServer
        ? this.convertDataFromServer(updated)
        : updated;
    throw new Error(`Не удалось обновить ${this.name}`);
  };

  updatePut = async (data: T): Promise<T> => {
    const updated = await fetchRequest.put(
      `/${this.url}`,
      this.convertDataToServer ? this.convertDataToServer(data) : data
    );
    if (updated)
      return this.convertDataFromServer
        ? this.convertDataFromServer(updated)
        : updated;
    throw new Error(`Не удалось обновить ${this.name}`);
  };

  delete = async (id: number): Promise<T> => {
    const deleted = await fetchRequest.delete(`/${this.url}/${id}`);
    if (deleted)
      return this.convertDataFromServer
        ? this.convertDataFromServer(deleted)
        : deleted;
    throw new Error(`Не удалось удалить ${this.name}`);
  };

  get = async (id: number, params: F): Promise<T> => {
    const data = await fetchRequest.get(`/${this.url}/${id}`, params);
    if (data)
      return this.convertDataFromServer
        ? this.convertDataFromServer(data)
        : data;
    throw new Error(`Не удалось получить ${this.name}`);
  };

  fetch = async (
    params: FetchListParams<F> = initialFetchListParams as FetchListParams<F>
  ): Promise<ListResponse<Fetch>> => {
    const findData = await fetchRequest.get(`/${this.url}`, {
      ...initialFetchListParams,
      ...params,
    });
    if (findData) {
      return this.convertDataFromServer
        ? { ...findData, data: findData.data.map(this.convertDataFromServer) }
        : findData;
    }
    throw new Error(`Не удалось получить ${this.name}`);
  };
}
