const apiUrl = 'https://nominatim.openstreetmap.org/';
const osm = {
  search: async (query: string) => {
    try {
      const response = await window.fetch(
        `${apiUrl}/search?q=${query}&format=json`
      );
      if (response.ok) {
        if (response.status === 204) {
          return null;
        }
        return await response.json();
      }
    } catch (error) {}
  },
  reverse: async (lat: number, lon: number) => {
    try {
      const response = await window.fetch(
        `${apiUrl}/reverse?lat=${lat}&lon=${lon}&format=json`
      );
      if (response.ok) {
        if (response.status === 204) {
          return null;
        }
        return await response.json();
      }
    } catch (error) {}
  },
};

export default osm;
