import { useQuery } from 'react-query';
import { orgUnitApi } from '../../api';

const useGet = (id: number) => {
  const { isLoading, data } = useQuery(
    ['getOrgUnit', id],
    () => {
      return orgUnitApi.get(id);
    },
    {
      enabled: Boolean(id),
    }
  );
  return { isLoading, data };
};

export default useGet;
