import type { User, Access } from '../types';
import { fetchRequest, CommonApi, CommonApiMethod } from '.';

type UserApi = CommonApiMethod & {
  me?: any;
  changePassword?: any;
  changeRole?: any;
};

const user: UserApi = new CommonApi<User>('users', 'Пользователи');

user.me = async (): Promise<User> => {
  const user = await fetchRequest.get(`/users/me`);
  if (user) {
    const {
      role: { access },
    } = user;
    return { ...user, access: access?.map(({ type }: Access) => type) };
  }
  throw new Error('Не удалось получить пользователя');
};

user.changePassword = async (data: {
  id: number;
  paswword: string;
}): Promise<User> => {
  const response = await fetchRequest.patch(`/users/change-pass`, data);
  if (response) return response;
  throw new Error('Не удалось изменить пароль');
};

user.changeRole = async (user: User): Promise<User> => {
  const response = await fetchRequest.patch(`/users/change-role`, user);
  if (response) return response;
  throw new Error('Не удалось изменить пароль');
};

export default user;
