import { useMutation } from 'react-query';
import { locationApi } from '../../api';
import { Location } from '../../types';

const useAddUpdate = () => {
  const mutation = useMutation((location: Location): any => {
    return location?.id
      ? locationApi.updatePut(location)
      : locationApi.add(location);
  });
  return mutation;
};

export default useAddUpdate;
