import { List, Form } from '../../pages/OrgUnits';
import { accessTypeEnum } from '../../lib/enums';

const roles = [
  {
    component: List,
    path: '/admin/org-units',
    access: [accessTypeEnum.admin],
  },
  {
    component: Form,
    path: '/admin/org-units/new',
    access: [accessTypeEnum.admin],
  },
  {
    component: Form,
    path: '/admin/org-units/:id/edit',
    access: [accessTypeEnum.admin],
  },
];
export default roles;
