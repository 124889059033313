import { useQuery } from 'react-query';
import { roleApi } from '../../api';
import { Role } from '../../types';

export const useRole = (id: number) => {
  const { isLoading, data } = useQuery(
    ['getRole', id],
    () => {
      return roleApi.get(id);
    },
    {
      enabled: Boolean(id),
      select: (role: Role) => {
        return {
          ...role,
          access: role.access.map((access) => {
            return String(access.type);
          }),
        };
      },
    }
  );
  return { isLoading, data };
};
