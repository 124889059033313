import { useQuery } from 'react-query';
import { locationApi } from '../../api';

const useFetch = (page: number | undefined) => {
  const { isLoading, data, refetch } = useQuery(
    ['fetchLocations', page],
    () => locationApi.fetch({ page }),
    {
      enabled: Boolean(page),
    }
  );
  return { isLoading, data, refetch };
};

export default useFetch;
