import React from 'react';
import { styled } from '@mui/material/styles';
import { Skeleton, Typography } from '@mui/material';

const Container = styled('div')(({ theme }) => {
  return {
    padding: theme.spacing(1),
  };
});
const Title = styled(Typography)(({ theme }) => {
  return {
    color: theme.palette.grey[500],
    fontSize: 16,
  };
});
type Props = {
  title: string;
  text: string;
  isLoading?: boolean;
  noWrap?: boolean;
};
const Label = ({ title, text, isLoading = false, noWrap = true }: Props) => {
  return (
    <Container>
      <Title noWrap={noWrap}>{title}</Title>
      <Typography noWrap={noWrap} paragraph>
        {isLoading ? <Skeleton variant="text" /> : text}
      </Typography>
    </Container>
  );
};
export default Label;
