import React from "react";
import { LogoWrapper, BackgroundImage } from "components/common";
import LoginForm from "./LoginForm";
import { LoginContent, SupportInfo } from "./elements";

const LoginScreen = () => {
  return (
    <BackgroundImage>
      <LoginContent>
        <LogoWrapper />
        <LoginForm />
      </LoginContent>
      <SupportInfo>
        Телефоны технической поддержки: +7 (987) 225-55-16, (843) 225-55-16
      </SupportInfo>
    </BackgroundImage>
  );
};

export default LoginScreen;
