import React from 'react';
import { Breadcrumbs, Divider, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => {
  return {
    background: 'white',
    padding: theme.spacing(2),
    boxShadow: 'rgb(0 0 0 / 7 %) 6px 0px 10px',
  };
});
type PropsCrumb = {
  label: string;
  to?: string;
};
type Props = {
  crumbs: PropsCrumb[];
  divider?: boolean;
};
const CustomBreadcrumbs = ({ crumbs, divider = true }: Props) => {
  return (
    <>
      {divider && <Divider />}
      <StyledBreadcrumbs aria-label="breadcrumb">
        {crumbs.map(({ to, label }: PropsCrumb, index: number) => {
          return to ? (
            <Link
              key={`breadcrumb-${index}`}
              underline="hover"
              color="inherit"
              href={to}
            >
              {label}
            </Link>
          ) : (
            <Typography key={`breadcrumb-${index}`} color="text.primary">
              {label}
            </Typography>
          );
        })}
      </StyledBreadcrumbs>
    </>
  );
};

export default CustomBreadcrumbs;
