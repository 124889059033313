import { useMutation } from 'react-query';
import { knsApi } from '../../api';
import { Kns } from '../../types';

const useAddUpdate = () => {
  const mutation = useMutation((kns: Kns): any => {
    return kns?.id ? knsApi.updatePut(kns) : knsApi.add(kns);
  });
  return mutation;
};

export default useAddUpdate;
