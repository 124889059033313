export enum positionsEnum {
    none = 0,
    rigger = 1,
    driver = 2,
    dispatcher = 3,
    mechanic = 4,
    specialist = 5,
    engineer = 6,
    manager = 7,
    medic = 8,
    lulechnik = 9,
    safetyTechnician = 10,
    gpmController = 11,
    truckCranesMachinist = 12,
    carLiftOperator = 13,
    manipulatorCranesOperator = 14,
    safetyCarLift = 15
}

export type PositionsType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15;

export const positionsType = {
    0: 'Отсутствует',
    1: 'Стропальщики',
    2: 'Водитель',
    3: 'Диспетчер',
    4: 'Механик',
    5: 'Специалист',
    6: 'Главный инженер',
    7: 'Менеджер',
    8: 'Медицинский работник',
    9: 'Рабочий люльки',
    10: 'Специалист, ответственный за безопасное производство работ с применением автокрана (крана-манипулятора)',
    11: 'Специалист, ответственный за содержание ПС в работоспособном состоянии',
    12: 'Машинист крана(крановщик)',
    13: 'Машинист подъемника(вышки)',
    14: 'Оператор(машинист) крана-манипулятора',
    15: 'Специалист, ответственный за безопасное производство работ с применением подъёмников (вышек)',
};