import type { User } from '../types';
import { CommonApi, CommonApiMethod, fetchRequest } from '.';

type RoleApi = CommonApiMethod;
const url = 'role';
const role: RoleApi = new CommonApi<User>(url, 'Роли');
const changeOrgUnit = async (
  id: number,
  toInclude: number[],
  toExclude: number[],
) => {
  const updated = await fetchRequest.patch(`/${url}/${id}/changeOrgUnit`, {
    id,
    toInclude,
    toExclude,
  });
  if (updated) return updated;
  throw new Error(`Не удалось обновить подразделения Роли`);
};

export default { ...role, changeOrgUnit };
