// @flow
import type { OrgUnit } from '../types';
import { fetchRequest, CommonApi, CommonApiMethod } from '.';

type OrgUnitsApi = CommonApiMethod & {
  tree?: any;
};
const route = 'orgUnit';
const orgUnit: OrgUnitsApi = new CommonApi<OrgUnit>(route, 'Подразделения');

orgUnit.tree = async (): Promise<OrgUnit> => {
  const orgUnit = await fetchRequest.get(`/${route}/tree`);
  if (orgUnit) return orgUnit;
  throw new Error('Не удалось получить подразделения');
};

export default orgUnit;
