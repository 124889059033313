import { useMutation } from 'react-query';
import { userApi } from '../../api';
import { User } from '../../types';

export const useChangeRoleUser = () => {
  const mutation = useMutation((user: User): any => {
    return userApi.changeRole(user);
  });
  return mutation;
};
