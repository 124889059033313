import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material';
import { positionsType } from '../../lib/enums';

type Props = SelectProps & {
  helperText: any;
};
const PositionSelect = (props: Props) => {
  return (
    <>
      <FormControl fullWidth size="small" error={Boolean(props.helperText)}>
        <InputLabel id="access-select">Должность</InputLabel>
        <Select
          labelId="access-select"
          id="access-select"
          label="Доступы"
          {...props}
        >
          {Object.keys(positionsType).map((key) => {
            return (
              <MenuItem value={key}>
                {/* @ts-ignore */}
                {positionsType[key]}
              </MenuItem>
            );
          })}
        </Select>
        {props.helperText && (
          <FormHelperText>{props.helperText}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};
export default PositionSelect;
