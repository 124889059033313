import { List, Form } from '../../pages/Employee';
import { accessTypeEnum } from '../../lib/enums';

const roles = [
    {
        component: List,
        path: '/admin/employee',
        access: [accessTypeEnum.admin],
    },
    {
        component: Form,
        path: '/admin/employee/new',
        access: [accessTypeEnum.admin],
    },
    {
        component: Form,
        path: '/admin/employee/:id/edit',
        access: [accessTypeEnum.admin],
    },
];
export default roles;
