import { useQuery } from 'react-query';
import { employeeApi } from '../../api';
import { Employee } from '../../types';

const useGet = (id: number) => {
  const { isLoading, data } = useQuery(
    ['getEmployee', id],
    () => {
      return employeeApi.get(id);
    },
    {
      enabled: Boolean(id),
      select: (employee: Employee) => ({
        ...employee,
        position: String(employee.position)
      })
    }
  );
  return { isLoading, data };
};

export default useGet;
