import { useMutation } from 'react-query';
import { journalVehicleApi } from '../../api';
import { JournalVehicle } from '../../types';

const useAddUpdate = () => {
  const mutation = useMutation((journalVehicle: JournalVehicle): any => {
    return journalVehicle?.id
      ? journalVehicleApi.updatePut(journalVehicle)
      : journalVehicleApi.add(journalVehicle);
  });
  return mutation;
};

export default useAddUpdate;
