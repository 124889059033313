export enum deviceWorkStatusEnum {
  auto = 0,
  manual = 1,
  dispatch = 2,
}

export type DeviceWorkStatus = 0 | 1 | 2;

export const deviceWorkStatus = {
  0: 'Автоматический',
  1: 'Ручной',
  2: 'Диспетчерский',
};
