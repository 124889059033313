import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { Header } from '../../components/layouts';
import { Breadcrumbs, Card } from '../../components/ui';
import { useAddUpdateOrgUnit, useOrgUnit } from '../../lib/hooks';
import { OrgUnit } from '../../lib/types';
import InnerForm from './components/InnerForm';

const route = '/admin/org-units';
const Form = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const mutation = useAddUpdateOrgUnit();
  const orgUnit = useOrgUnit(Number(id));
  const handleSubmit = (orgUnit: OrgUnit) => {
    mutation.mutate(orgUnit, {
      onSuccess: () => {
        navigate(`${route}`);
      },
    });
  };
  const handleCancel = () => {
    navigate(-1);
  };
  return (
    <>
      <Header
        title={
          id
            ? 'Редактирование подразделения'
            : 'Добавление нового подразделения'
        }
      />
      <Breadcrumbs
        crumbs={[
          { label: 'Главная', to: '/' },
          { label: 'Подразделения', to: route },
          { label: 'Подразделение' },
        ]}
      />
      <Card>
        <InnerForm
          isLoading={orgUnit.isLoading}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          orgUnit={
            orgUnit?.data
              ? (orgUnit.data as unknown as OrgUnit)
              : ({} as OrgUnit)
          }
        />
      </Card>
    </>
  );
};
export default Form;
