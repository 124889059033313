import { useQuery } from 'react-query';
import { employeeApi } from '../../api';

const useFetch = (page: number | undefined) => {
  const { isLoading, data, refetch } = useQuery(
    ['fetchEmployees', page],
    () => employeeApi.fetch({ page }),
    {
      enabled: Boolean(page),
    }
  );
  return { isLoading, data, refetch };
};

export default useFetch;
