export enum motorStatusEnum {
  off = 0,
  on = 1,
  reserve = 2,
}

export type MotorStatus = 0 | 1 | 2;

export const motorStatus = {
  0: 'Выключен',
  1: 'Включен',
  2: 'В резерве',
};
