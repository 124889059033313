import { useMutation } from 'react-query';
import { locationApi } from '../../api';

const useDelete = () => {
  const mutation = useMutation((id: number): any => {
    return locationApi.delete(id);
  });
  return mutation;
};

export default useDelete;
