import { useQuery } from 'react-query';
import { roleApi } from '../../api';

export const useRoles = (page: number | undefined) => {
  const { isLoading, data, refetch } = useQuery(
    ['fetchRoles', page],
    () => roleApi.fetch({ page }),
    {
      enabled: Boolean(page),
    }
  );
  return { isLoading, data, refetch };
};
