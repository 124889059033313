import { List, Form } from '../../pages/Location';
import { accessTypeEnum } from '../../lib/enums';

const locations = [
  {
    component: List,
    path: '/admin/locations',
    access: [accessTypeEnum.admin],
  },
  {
    component: Form,
    path: '/admin/locations/new',
    access: [accessTypeEnum.admin],
  },
  {
    component: Form,
    path: '/admin/locations/:id/edit',
    access: [accessTypeEnum.admin],
  },
];
export default locations;
