import React from 'react';
import { TextFieldProps } from '@mui/material';
import { Employee } from '../../lib/types';
import { employeeApi } from '../../lib/api';

import AutoCompleteSelect from './AutoCompleteSelect';

type Props = {
  onChange: (
    id: number | number[] | undefined,
    employee: Employee | undefined,
  ) => void;
} & Omit<TextFieldProps, 'onChange'>;
const Select = (props: Props) => {
  return (
    <>
      <AutoCompleteSelect
        id="employeeId"
        name="employeeId"
        label="Выберите сотрудника"
        size="small"
        fullWidth
        fetch={async (params) => {
          const res = await employeeApi.fetch(params);
          return {
            ...res,
            data:
              res?.data?.map((employee: Employee) => {
                return {
                  ...employee,
                  name: `${employee.firstName} ${employee.middleName} ${employee.lastName}`,
                };
              }) ?? [],
          };
        }}
        fetchSingle={async (id: number) => {
          const employee = await employeeApi.get(id);
          return {
            ...employee,
            name: `${employee.firstName} ${employee.middleName} ${employee.lastName}`,
          };
        }}
        {...props}
      />
    </>
  );
};
export default Select;
