import isEmpty from 'lodash/isEmpty';
const tokenName = 'user';

type Props = {
  getToken: () => string | null;
  setToken: (newToken: string) => void;
  clearToken: () => void;
};

export const tokenManager = (): Props => {
  // Токен авторизации
  let token: string | null;
  // Получение токена из мемоизированной переменной или localStorage
  const getToken = (): string | null =>
    (isEmpty(token) ? '' : token) || localStorage.getItem(tokenName) || null;

  return {
    // Получение токена
    getToken,
    // Обновление токена
    setToken: (newToken: string): void => {
      if (newToken) {
        localStorage.setItem(tokenName, newToken);
        token = newToken;
      }
    },
    clearToken: () => localStorage.removeItem(tokenName),
  };
};

const tokenWrapper = tokenManager();

export default tokenWrapper;
