import React from 'react';
import { TextFieldProps } from '@mui/material';
import { OrgUnit } from '../../lib/types';
import { orgUnitApi } from '../../lib/api';

import AutoCompleteSelect from './AutoCompleteSelect';

type Props = {
  onChange: (
    orgUnitId: number | number[] | undefined,
    orgUnit: OrgUnit | undefined,
  ) => void;
  multiple?: boolean;
} & Omit<TextFieldProps, 'onChange'>;
const Select = (props: Props) => {
  return (
    <>
      <AutoCompleteSelect
        id="orgUnitId"
        name="orgUnitId"
        label="Выберите подразделение"
        size="small"
        fullWidth
        fetch={orgUnitApi.fetch}
        fetchSingle={orgUnitApi.get}
        {...props}
      />
    </>
  );
};
export default Select;
