import { useMutation } from 'react-query';
import { orgUnitApi } from '../../api';
import { OrgUnit } from '../../types';

const useAddUpdate = () => {
  const mutation = useMutation((orgUnit: OrgUnit): any => {
    return orgUnit?.id ? orgUnitApi.update(orgUnit) : orgUnitApi.add(orgUnit);
  });
  return mutation;
};

export default useAddUpdate;
