import { useMutation } from 'react-query';
import { roleApi } from '../../api';
import { Role } from '../../types';

const covertToServer = (role: any) => {
  return {
    ...role,
    access: [
      //@ts-ignore
      ...(role?.access?.map((access) => {
        return {
          type: Number(access),
          roleId: role?.id,
        };
      }) ?? []),
    ].filter((access) => access.type !== null),
  };
};

export const useAddUpdateRole = () => {
  const mutation = useMutation((role: Role): any => {
    const data = covertToServer(role);
    return role?.id ? roleApi.updatePut(data) : roleApi.add(data);
  });
  return mutation;
};
