export enum commandTypeEnum {
  emergencySituation = 0,
  fromMaster = 1,
  fromSlave = 2,
  timePacket = 3,
}

export type CommandType = 0 | 1 | 2 | 3;

export const commandType = {
  0: 'Отправка аварийной ситуации',
  1: 'Отправка управляющей информации от ведущего',
  2: 'Отправка данных от ведомого',
  3: 'Пакет времени',
};
