import React from "react";
import { Button, Container, Typography } from "@mui/material";
import { Construction } from "@mui/icons-material";
const NotFound = () => {
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        alignItems: "center",
        flexDirection: "column",
      }}>
      <Construction sx={{ fontSize: 100 }} color="warning" />
      <Typography variant="h5">Страница не найдена</Typography>
      <Button href="/">Перейти на главную</Button>
    </Container>
  );
};
export default NotFound;
