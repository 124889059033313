import React from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { TextField, Button } from '@mui/material';
import { authApi } from '../../lib/api';
import tokenWrapper from '../../lib/tokenManager';

const validationSchema = yup.object({
  userName: yup.string().required('Обязательное поле'),
  password: yup.string().required('Обязательное поле'),
});
const LoginForm = () => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      userName: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setErrors }) => {
      try {
        const { accessToken } = await authApi.login(values);
        tokenWrapper.setToken(accessToken);
        navigate(window.history?.state?.uri ?? '/');
      } catch (error) {
        setErrors({
          password: 'Неверный логин или пароль',
        });
      }
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id="userName"
          name="userName"
          label="Имя пользователя"
          value={formik.values.userName}
          onChange={formik.handleChange}
          error={formik.touched.userName && Boolean(formik.errors.userName)}
          helperText={formik.touched.userName && formik.errors.userName}
        />
        <TextField
          fullWidth
          id="password"
          name="password"
          label="Пароль"
          type="password"
          style={{ marginTop: '16px' }}
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
        <Button type="submit">Вход</Button>
      </form>
    </div>
  );
};

export default LoginForm;
