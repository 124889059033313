import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Grid, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { OrgUnit } from '../../../lib/types';
import { OrgUnitSelect } from '../../../components/selects';
import { StyledDiv } from '../../../components/ui';

const validationSchema = yup.object({
  name: yup.string().required('Обязательное поле'),
});

type Props = {
  handleSubmit: (orgUnit: OrgUnit) => void;
  handleCancel: () => void;
  orgUnit: OrgUnit;
  isLoading: boolean;
};
const InnerForm = ({
  handleSubmit,
  orgUnit,
  isLoading,
  handleCancel,
}: Props) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: orgUnit,
    validationSchema,
    onSubmit: handleSubmit,
  });
  const onSubmit = (e: React.FormEvent<HTMLFormElement> | undefined) => {
    formik.handleSubmit(e);
  };
  const isSend = Boolean(formik.submitCount);
  return (
    <StyledDiv>
      <form onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              id="name"
              name="name"
              label="Наименование подразделения"
              type="text"
              InputProps={{
                autoComplete: 'off',
              }}
              value={formik.values?.name ?? ''}
              onChange={formik.handleChange}
              error={
                (formik.touched.name || isSend) && Boolean(formik.errors.name)
              }
              helperText={(formik.touched.name || isSend) && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <OrgUnitSelect
              disabled={Boolean(formik.values?.id)}
              value={formik.values?.parentId ?? ''}
              onChange={(
                parentId: number | number[] | undefined,
                orgUnit: OrgUnit | undefined,
              ) => {
                formik.setFieldValue('parentId', parentId);
              }}
              error={
                (formik.touched.parentId || isSend) &&
                Boolean(formik.errors.parentId)
              }
              helperText={
                (formik.touched.parentId || isSend) && formik.errors.parentId
              }
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              loading={isLoading}
              color="primary"
              variant="contained"
              type="submit">
              Cохранить
            </LoadingButton>
            <LoadingButton
              onClick={handleCancel}
              loading={isLoading}
              style={{ float: 'right' }}>
              Отменить
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </StyledDiv>
  );
};
export default InnerForm;
