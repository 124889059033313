import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Employee, Role, User } from '../../../lib/types';
import { RoleSelect, EmployeeSelect } from '../../../components/selects';
import { StyledDiv } from '../../../components/ui';

const validationSchema = yup.object({
  userName: yup.string().required('Обязательное поле'),
  roleId: yup.string().required('Обязательное поле'),
  employeeId: yup.string().required('Обязательное поле'),
  password: yup
    .string()
    .min(6, 'Пароль должен быть не менее 6 символов')
    .required('Обязательное поле'),
});

type Props = {
  handleSubmit: (user: User) => void;
  handleChangePass: (password: string) => void;
  handleChangeRole: (roleId: number) => void;
  handleCancel: () => void;
  user: User;
  isLoading: boolean;
};
const InnerForm = ({
  handleSubmit,
  user,
  isLoading,
  handleCancel,
  handleChangeRole,
  handleChangePass,
}: Props) => {
  const [changePassword, setChangePassword] = useState(false);
  const [isSend, setSend] = useState(false);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: user,
    validationSchema,
    onSubmit: handleSubmit,
  });
  const handleChangePassword = () => {
    setChangePassword(true);
    formik.setFieldValue('password', '');
  };
  const onSubmit = (e: any) => {
    e.preventDefault();
    if (formik.values.id) {
      const roleId = formik?.values?.roleId;
      const password = formik?.values?.password;
      roleId && handleChangeRole(roleId);
      changePassword && password && handleChangePass(password);
    } else {
      formik.handleSubmit(e);
    }
    setSend(true);
  };
  return (
    <StyledDiv>
      <form onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              id="userName"
              name="userName"
              label="Имя пользователя"
              type="text"
              disabled={Boolean(formik.values.id)}
              InputProps={{
                autoComplete: 'off',
              }}
              value={formik.values.userName || ''}
              onChange={formik.handleChange}
              error={formik.touched.userName && Boolean(formik.errors.userName)}
              helperText={formik.touched.userName && formik.errors.userName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {changePassword || !Boolean(formik.values.id) ? (
              <TextField
                fullWidth
                size="small"
                id="password"
                name="password"
                label="Пароль"
                type="password"
                InputProps={{
                  autoComplete: 'off',
                }}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            ) : (
              <Button onClick={handleChangePassword}>Сменить пароль</Button>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <EmployeeSelect
              onChange={(
                employeeId: number | number[] | undefined,
                employee: Employee | undefined,
              ) => {
                formik.setFieldValue('employeeId', employeeId);
              }}
              value={formik.values.employeeId}
              error={isSend && Boolean(formik.errors.employeeId)}
              helperText={isSend && formik.errors.employeeId}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <RoleSelect
              onChange={(
                roleId: number | number[] | undefined,
                role: Role | undefined,
              ) => {
                formik.setFieldValue('roleId', roleId);
              }}
              value={formik.values.roleId}
              error={isSend && Boolean(formik.errors.roleId)}
              helperText={isSend && formik.errors.roleId}
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              loading={isLoading}
              color="primary"
              variant="contained"
              type="submit">
              Cохранить
            </LoadingButton>
            <LoadingButton
              onClick={handleCancel}
              loading={isLoading}
              style={{ float: 'right' }}>
              Отменить
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </StyledDiv>
  );
};
export default InnerForm;
