import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Grid, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { OrgUnit, Role } from '../../../lib/types';
import { AccessSelect, OrgUnitSelect } from '../../../components/selects';
import { StyledDiv } from '../../../components/ui';

const validationSchema = yup.object({
  name: yup.string().required('Обязательное поле'),
  access: yup.array().min(1, 'Обязательное поле'),
});

type Props = {
  handleSubmit: (role: Role & { localOrganizationUnits: number[] }) => void;
  handleCancel: () => void;
  role: Role;
  isLoading: boolean;
};
const InnerForm = ({ handleSubmit, role, isLoading, handleCancel }: Props) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...role,
      localOrganizationUnits:
        role?.organizationUnits?.map(({ id }) => id) ?? [],
      access: role?.access ?? [],
    },
    validationSchema,
    onSubmit: handleSubmit,
  });
  const onSubmit = (e: React.FormEvent<HTMLFormElement> | undefined) => {
    formik.handleSubmit(e);
  };
  const isSend = Boolean(formik.submitCount);
  return (
    <StyledDiv>
      <form onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              id="name"
              name="name"
              label="Название роли"
              type="text"
              InputProps={{
                autoComplete: 'off',
              }}
              value={formik.values?.name ?? ''}
              onChange={formik.handleChange}
              error={
                (formik.touched.name || isSend) && Boolean(formik.errors.name)
              }
              helperText={(formik.touched.name || isSend) && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AccessSelect
              value={formik.values?.access ?? []}
              onChange={(e) => {
                formik.setFieldValue('access', e.target.value);
              }}
              error={
                (formik.touched.access || isSend) &&
                Boolean(formik.errors.access)
              }
              helperText={
                (formik.touched.access || isSend) && formik.errors.access
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <OrgUnitSelect
              value={formik.values?.localOrganizationUnits ?? []}
              onChange={(
                orgUnitId: number | number[] | undefined,
                organizationUnit: OrgUnit | undefined,
              ) => {
                formik.setFieldValue('localOrganizationUnits', orgUnitId);
              }}
              error={
                (formik.touched.organizationUnits || isSend) &&
                Boolean(formik.errors.organizationUnits)
              }
              multiple
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              loading={isLoading}
              color="primary"
              variant="contained"
              type="submit">
              Cохранить
            </LoadingButton>
            <LoadingButton
              onClick={handleCancel}
              loading={isLoading}
              style={{ float: 'right' }}>
              Отменить
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </StyledDiv>
  );
};
export default InnerForm;
