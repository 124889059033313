import Image from "./background.svg";
import styled from "@emotion/styled";

export const BackgroundImage = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f4f8;
  background-image: url(${Image});
  background-size: cover;
`;
