export enum pumpStatuseEnum {
  off = 0,
  on = 1,
  reserve = 2,
}

export type PumpStatus = 0 | 1 | 2;

export const pumpStatus = {
  0: 'Выключен',
  1: 'Включен',
  2: 'В резерве',
};
