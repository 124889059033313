import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { Header } from '../../components/layouts';
import { Breadcrumbs, Card } from '../../components/ui';
import {
  useAddUpdateUser,
  useUser,
  useChangePassUser,
  useChangeRoleUser,
} from '../../lib/hooks';
import { User } from '../../lib/types';

import InnerForm from './components/InnerForm';

const Form = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const mutation = useAddUpdateUser();
  const user = useUser(Number(id));
  const mutationChangePass = useChangePassUser();
  const mutationChangeRole = useChangeRoleUser();
  const handleSubmit = (user: User) => {
    mutation.mutate(user, {
      onSuccess: (user: any) => {
        return navigate(`/admin/users/${user.id}`);
      },
    });
  };
  const handleChangePass = (password: string) => {
    const { data } = user;
    mutationChangePass.mutate({ id: data.id, password });
  };
  const handleChangeRole = (roleId: number) => {
    const { data } = user;
    mutationChangeRole.mutate(
      { ...data, roleId },
      {
        onSuccess: (user: any) => {
          return navigate(`/admin/users/${user.id}`);
        },
      }
    );
  };
  const handleCancel = () => {
    navigate(-1);
  };
  return (
    <>
      <Header
        title={
          id ? 'Редактирование пользователя' : 'Добавление нового пользователя'
        }
      />
      <Breadcrumbs
        crumbs={[
          { label: 'Главная', to: '/' },
          { label: 'Пользователи', to: '/admin/users' },
          { label: 'Пользователь' },
        ]}
      />
      <Card>
        <InnerForm
          isLoading={mutation.isLoading || user.isLoading}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          handleChangePass={handleChangePass}
          handleChangeRole={handleChangeRole}
          user={user?.data ? user.data : ({} as User)}
        />
      </Card>
    </>
  );
};
export default Form;
